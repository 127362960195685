import gql from "graphql-tag";

export const WORKPLAN = gql`
  query FilterWorkplans($criteria: WorkplanFilterCriteria!) {
    filterWorkplans(criteria: $criteria) {
      id
      date
      countAsOvertime
      assignedShifts {
        id
      }
      leaveRequest {
        id
      }
      timesheets {
        id
        clockInTimestamp
        clockOutTimestamp
        totalOvertimeInMinutes
        totalMissingTimeInMinutes
        latelyClockInTimeInMinutes
        earlyClockOutTimeInMinutes
      }
    }
  }
`;

export const FILTER_CLOCK_RECORDS = gql`
  query FilterWithPaginateClockRecords($criteria: ClockRecordFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateClockRecords(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
        totalPages
        numberOfElements
        pageNumber
        pageSize
      }
      content {
        id
        employee
        firstName
        lastName
        eventType
        identityType
        imageUrl
        terminalName
        terminalType
        timestamp
        locationAccuracy
        latitude
        longitude
        description
      }
    }
  }
`;

export const EVENTS = gql`
  query filterActivities($criteria: ActivityFilterCriteria!) {
    filterActivities(criteria: $criteria) {
      id
      name
      color
      beginDate
      endDate
      beginTime
      endTime
      content
      location
      online
      activityType {
        name
      }
    }
  }
`;

export const ACTIVITIES = gql`
  query dashboard($manager: ID, $beginDate: String!, $endDate: String!, $branches: [ID!]) {
    dashboard(manager: $manager, beginDate: $beginDate, endDate: $endDate, branches: $branches) {
      activities {
        id
        name
        color
        beginDate
        endDate
        beginTime
        endTime
        content
        location
        online
        activityType {
          name
        }
        owner {
          id
          firstName
          lastName
        }
        guests {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const CURRENCIES = gql`
  query currencies {
    currencies {
      code
      buying
      selling
      name
    }
  }
`;

export const WEATHER = gql`
  query weather {
    weather {
      main
      icon
      description
      temp
      feelingTemp
      tempMin
      tempMax
      pressure
      humidity
    }
  }
`;

export const ORGANIZATION_DETAIL = gql`
  {
    organizationDetail {
      about
      mission
      vision
    }
  }
`;

export const MENU = gql`
  query filterMenus($criteria: MenuFilterCriteria!) {
    filterMenus(criteria: $criteria) {
      color
      name
      items
      date
    }
  }
`;

export const DASHBOARD_EXPENSE_IMPREST = gql`
  query dashboard($manager: ID, $beginDate: String!, $endDate: String!) {
    dashboard(manager: $manager, beginDate: $beginDate, endDate: $endDate) {
      expenseRequests {
        id
        approver {
          id
        }
        expense {
          name
          type {
            name
          }
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
              unit {
                name
              }
            }
          }
          requested {
            amount
            code
          }
          timestamp
        }
      }
      imprestRequests {
        id
        approver {
          id
        }
        imprest {
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
              unit {
                name
              }
            }
          }
          requested {
            amount
            code
          }
          period
        }
      }
    }
  }
`;

export const DASHBOARD_REQUEST = gql`
  query dashboard($manager: ID, $beginDate: String!, $endDate: String!) {
    dashboard(manager: $manager, beginDate: $beginDate, endDate: $endDate) {
      leaveRequests {
        id
        approver {
          id
        }

        leaveRequest {
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
              unit {
                name
              }
            }
          }
          leaveType {
            name
            icon
          }
          leaveRequestDays {
            date
            fromTime
            lengthInMinutes
            duration
          }
        }
      }
      overtimeRequests {
        id
        approver {
          id
        }
        overtime {
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
              unit {
                name
              }
            }
          }
          period
          overtimeInMinutes
          workplan {
            date
          }
        }
      }
    }
  }
`;

export const DASHBOARD_REMINDERS = gql`
  query dashboard($manager: ID, $beginDate: String!, $endDate: String!, $branches: [ID!]) {
    dashboard(manager: $manager, beginDate: $beginDate, endDate: $endDate, branches: $branches) {
      birthDates {
        id
        firstName
        lastName
        profile {
          imageUrl
        }
        employment {
          branch {
            name
          }
          unit {
            name
          }
        }
        personal {
          birthDate
        }
      }
      hiringDates {
        id
        firstName
        lastName
        profile {
          imageUrl
        }
        employment {
          hiredDate
          unit {
            id
            name
          }
          branch {
            id
            name
          }
          jobInfo {
            id
            name
          }
        }
      }
      messages {
        title
        body
        timestamp
        sender {
          id
          firstName
          lastName
          profile {
            imageUrl
          }
          employment {
            unit {
              id
              name
            }
            branch {
              id
              name
            }
            jobInfo {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const FILTER_LEAVE_REQUEST = gql`
  query filterLeaveRequests($criteria: LeaveRequestFilterCriteria!) {
    filterLeaveRequests(criteria: $criteria) {
      id
      leaveRequestStatus
      requester {
        id
        firstName
        lastName
        profile {
          imageUrl
        }
      }
      employee {
        id
        firstName
        lastName
        profile {
          imageUrl
        }
        employment {
          branch {
            name
          }
        }
      }
      leaveType {
        id
        color
        paymentType
        name
        icon
        restriction {
          durations
        }
      }
      leaveRequestDays {
        id
        date
        duration
        period
        leaveRequestDayType
        fromTime
        lengthInMinutes
      }
      beginLeaveRequestDay {
        date
        duration
        period
        fromTime
        lengthInMinutes
      }
      endLeaveRequestDay {
        date
        duration
        period
        fromTime
        lengthInMinutes
      }
    }
  }
`;

export const LEAVE_MANAGER_EMPLOYEES = gql`
  query FilterEmployees($criteria: EmployeeFilterCriteria!) {
    filterEmployees(criteria: $criteria) {
      id
    }
  }
`;
