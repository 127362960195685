<template>
  <app-content-layout>
    <v-row class="my-n3">
      <v-col cols="12" class="pa-0">
        <grid-layout
          :layout="layout"
          :responsive-layouts="layouts"
          :cols="cols"
          :col-num="12"
          :row-height="40"
          :is-draggable="true"
          :is-resizable="true"
          :vertical-compact="true"
          :margin="[14, 14]"
          :use-css-transforms="true"
          :responsive="true"
          @layout-updated="layoutUpdatedEvent"
          @breakpoint-changed="breakpointChangedEvent"
        >
          <grid-item
            class="elevation-2"
            v-for="item in layout"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            :key="item.i"
            :is-resizable="item.isResizable"
          >
            <component
              :is="item.component"
              :workplans="workplans"
              :events="events"
              :calendar-date.sync="calendarDate"
              :begin-date.sync="beginDate"
              :end-date.sync="endDate"
              :chart-loading="chartLoading"
              :calendar-loading="calendarLoading"
              :data-type.sync="dataType"
            />
          </grid-item>
        </grid-layout>
      </v-col>
    </v-row>
  </app-content-layout>
</template>

<script>
  import VueGridLayout from "vue-grid-layout";
  import { WORKPLAN, ACTIVITIES, LEAVE_MANAGER_EMPLOYEES, FILTER_LEAVE_REQUEST, DASHBOARD_REMINDERS } from "./query";
  import { DataType } from "./components/calendar/enums";

  const defaultLayouts = {
    lg: [
      { x: 0, y: 0, w: 4, h: 5, i: "0", component: "TimesheetChart", isResizable: true },
      { x: 4, y: 0, w: 4, h: 5, i: "1", component: "LatelyChart", isResizable: true },
      { x: 8, y: 0, w: 4, h: 10, i: "2", component: "Calendar", isResizable: true },
      { x: 0, y: 5, w: 4, h: 5, i: "3", component: "EarlyChart", isResizable: true },
      { x: 4, y: 5, w: 4, h: 5, i: "4", component: "OvertimeChart", isResizable: true },
      { x: 0, y: 10, w: 4, h: 8, i: "5", component: "ExpenseImprestRequests", isResizable: true },
      { x: 4, y: 10, w: 4, h: 8, i: "6", component: "LeaveOvertimeRequests", isResizable: true },
      { x: 8, y: 10, w: 4, h: 8, i: "7", component: "About", isResizable: true },
      { x: 0, y: 18, w: 8, h: 8, i: "8", component: "Reminders", isResizable: true },
      { x: 8, y: 18, w: 4, h: 8, i: "9", component: "FoodMenu", isResizable: true },
      { x: 0, y: 26, w: 8, h: 15, i: "10", component: "LastEvents", isResizable: true }
    ],
    md: [
      { x: 0, y: 0, w: 5, h: 5, i: "0", component: "TimesheetChart", isResizable: true },
      { x: 5, y: 0, w: 5, h: 5, i: "1", component: "LatelyChart", isResizable: true },
      { x: 0, y: 10, w: 10, h: 10, i: "4", component: "Calendar", isResizable: true },
      { x: 0, y: 5, w: 5, h: 5, i: "2", component: "EarlyChart", isResizable: true },
      { x: 5, y: 5, w: 5, h: 5, i: "3", component: "OvertimeChart", isResizable: true },
      { x: 0, y: 10, w: 5, h: 8, i: "5", component: "ExpenseImprestRequests", isResizable: true },
      { x: 5, y: 10, w: 5, h: 8, i: "6", component: "LeaveOvertimeRequests", isResizable: true },
      { x: 0, y: 18, w: 10, h: 8, i: "8", component: "Reminders", isResizable: true },
      { x: 0, y: 26, w: 10, h: 15, i: "10", component: "LastEvents", isResizable: true },
      { x: 0, y: 41, w: 10, h: 8, i: "7", component: "About", isResizable: true },
      { x: 0, y: 49, w: 5, h: 8, i: "9", component: "FoodMenu", isResizable: true }
    ],
    xs: [
      { x: 0, y: 0, w: 4, h: 5, i: "0", component: "TimesheetChart", isResizable: true },
      { x: 0, y: 5, w: 4, h: 5, i: "1", component: "LatelyChart", isResizable: true },
      { x: 0, y: 10, w: 4, h: 5, i: "2", component: "EarlyChart", isResizable: true },
      { x: 0, y: 15, w: 4, h: 5, i: "3", component: "OvertimeChart", isResizable: true },
      { x: 0, y: 20, w: 4, h: 10, i: "4", component: "Calendar", isResizable: true },
      { x: 0, y: 30, w: 4, h: 8, i: "5", component: "ExpenseImprestRequests", isResizable: true },
      { x: 0, y: 38, w: 4, h: 8, i: "6", component: "LeaveOvertimeRequests", isResizable: true },
      { x: 0, y: 46, w: 4, h: 8, i: "8", component: "Reminders", isResizable: true },
      { x: 0, y: 54, w: 4, h: 15, i: "10", component: "LastEvents", isResizable: true },
      { x: 0, y: 69, w: 4, h: 8, i: "7", component: "About", isResizable: true },
      { x: 0, y: 77, w: 4, h: 8, i: "9", component: "FoodMenu", isResizable: true }
    ]
  };

  const breakpoints = {
    lg: "lg",
    md: "md",
    sm: "sm",
    xs: "xs",
    xxs: "xxs"
  };

  const components = {
    LastEvents: () => import("./components/last-events"),
    Calendar: () => import("./components/calendar/index"),
    About: () => import("./components/about"),
    FoodMenu: () => import("./components/menu"),
    ExpenseImprestRequests: () => import("./components/expense-imprest"),
    LeaveOvertimeRequests: () => import("./components/requests"),
    Reminders: () => import("./components/reminders"),
    TimesheetChart: () => import("./components/TimesheetChart"),
    LatelyChart: () => import("./components/LatelyChart"),
    EarlyChart: () => import("./components/EarlyChart"),
    OvertimeChart: () => import("./components/OvertimeChart"),
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  };

  export default {
    name: "dashboard",
    components,
    data: vm => ({
      calendarDate: vm
        .$moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      beginDate: vm
        .$moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      endDate: vm
        .$moment()
        .endOf("month")
        .format("YYYY-MM-DD"),
      calendarLoading: false,
      events: [],
      workplans: [],
      chartLoading: false,
      cols: { lg: 12, md: 10, sm: 10, xs: 4, xxs: 4 },
      layouts: defaultLayouts,
      layout: defaultLayouts.lg,
      dataType: DataType.ACTIVITY,
      dataTypeFnMapper: {
        [DataType.ACTIVITY]: vm.fetchActivities,
        [DataType.LEAVE_REQUEST]: vm.fetchLeaveRequests,
        [DataType.REMINDER]: vm.fetchReminders
      },
      employees: []
    }),
    computed: {
      _beginDate() {
        const startOfMonth = this.$moment(this.beginDate).startOf("month");
        const day = startOfMonth.day();
        const subtracted = day === 0 ? 6 : day - 1;
        return startOfMonth.subtract(subtracted, "days").format("YYYY-MM-DD");
      },
      _endDate() {
        const endOfMonth = this.$moment(this.endDate).endOf("month");
        const day = endOfMonth.day();
        const added = 7 - (day === 0 ? 7 : day);
        return endOfMonth.add(added, "days").format("YYYY-MM-DD");
      }
    },
    watch: {
      beginDate() {
        this.fetchWorkplans();
        this.fetchData();
      },
      dataType() {
        this.fetchData();
      }
    },
    methods: {
      layoutUpdatedEvent(layout) {
        this.saveLayouts();
      },
      breakpointChangedEvent(breakpoint) {
        if (breakpoint === breakpoints.md || breakpoint === breakpoints.sm) {
          this.layout = this.layouts.md;
        } else if (breakpoint === breakpoints.xs || breakpoint === breakpoints.xxs) {
          this.layout = this.layouts.xs;
        } else {
          this.layout = this.layouts.lg;
        }
      },
      fetchWorkplans() {
        this.chartLoading = true;
        this.$apollo
          .query({
            query: WORKPLAN,
            variables: {
              criteria: {
                employees: [this.$store.state.auth.user.id],
                beginDate: this.beginDate,
                endDate: this.endDate
              }
            }
          })
          .then(({ data, errors }) => {
            if (!errors && !data.error) {
              this.workplans = data.filterWorkplans;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.chartLoading = false));
      },
      saveLayouts() {
        localStorage.setItem("layouts", JSON.stringify(this.layouts));
      },
      initLayouts() {
        try {
          const layouts = JSON.parse(localStorage.getItem("layouts"));
          const componentList = Object.keys(components);
          const layoutList = Object.values(layouts);
          layoutList.forEach(layout =>
            layout.forEach((item, index) => {
              if (!componentList.includes(item.component)) {
                layouts.splice(index, 1);
              }
            })
          );
          if (layouts) {
            this.layouts = layouts;
          }
        } catch (error) {
          console.error("layout init error: ", error);
          localStorage.removeItem("layouts");
        }
      },
      diff(beginDate, endDate, diffFormat = "days") {
        return this.$moment(endDate).diff(this.$moment(beginDate), diffFormat);
      },
      isSame(beginDate, endDate) {
        return this.$moment(beginDate).isSame(endDate);
      },
      add(date, i) {
        return this.$moment(date)
          .add(i, "day")
          .format("YYYY-MM-DD");
      },
      fetchData() {
        this.events = [];
        this.dataTypeFnMapper[this.dataType]();
      },
      createActivityEvent(activity, date = activity.beginDate) {
        const event = {};
        event.id = activity.id;
        event.date = date;
        event.color = activity.color;
        event.icon = "mdi-calendar";
        event.label = activity.activityType.name;
        event.title = activity.name;
        event.message = activity.content;
        event.subMessage = `${this.$helpers.formattedDate(activity.beginDate)} - ${this.$helpers.formattedDate(
          activity.endDate
        )}`;
        return event;
      },
      fetchActivities() {
        this.calendarLoading = true;
        this.$apollo
          .query({
            query: ACTIVITIES,
            variables: {
              beginDate: this._beginDate,
              endDate: this._endDate,
              manager: !this.$store.state.auth.user.isAdmin ? this.$store.state.auth.user.id : null,
              branches: this.$store.state.auth.user.restrictedAdmin ? this.$store.state.auth.user.adminBranches : []
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              data.dashboard.activities.forEach(activity => {
                if (this.isSame(activity.beginDate, activity.endDate)) {
                  this.events.push(this.createActivityEvent(activity));
                } else {
                  for (let i = 0; i <= this.diff(activity.beginDate, activity.endDate); i++) {
                    this.events.push(this.createActivityEvent(activity, this.add(activity.beginDate, i)));
                  }
                }
              });
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.calendarLoading = false));
      },
      createLeaveRequestEvent(leaveRequest, leaveRequestDay, date = leaveRequest.beginLeaveRequestDay.date) {
        const event = {};
        event.id = leaveRequest.id;
        event.date = date;
        event.color = leaveRequest.leaveType.color;
        event.icon = leaveRequest.leaveType.icon;
        event.label = leaveRequest.leaveType.name;
        event.title = `${leaveRequest.employee.firstName} ${leaveRequest.employee.lastName}`;
        event.message =
          this.$t(`leave_duration.${leaveRequestDay.duration}`, []) +
          " - " +
          this.$t(`leave_request_status.${leaveRequest.leaveRequestStatus}`);
        if (leaveRequestDay.duration === "HOURLY") {
          event.subMessage = `${leaveRequestDay.fromTime.slice(0, 5)} - ${this.$moment(
            leaveRequestDay.fromTime,
            "HH:mm"
          )
            .add(leaveRequestDay.lengthInMinutes, "minutes")
            .format("HH:mm")}`;
        } else {
          event.subMessage = `${this.$helpers.formattedDate(
            leaveRequest.beginLeaveRequestDay.date
          )} - ${this.$helpers.formattedDate(leaveRequest.endLeaveRequestDay.date)}`;
        }
        return event;
      },
      async fetchLeaveRequests() {
        let employees = [];
        if (!this.$store.state.auth.user.isAdmin) {
          const criteria = {};
          if (this.$store.state.auth.user.restrictedAdmin) {
            criteria.branches = this.$store.state.auth.user.adminBranches;
          } else {
            criteria.leaveManager = this.$store.state.auth.user.id;
          }
          employees = await this.fetchManagerEmployees(criteria);
          if (!employees.includes(this.$store.state.auth.user.id)) {
            employees.push(this.$store.state.auth.user.id);
          }
        }
        this.calendarLoading = true;
        this.$apollo
          .query({
            query: FILTER_LEAVE_REQUEST,
            variables: {
              criteria: {
                beginDate: this._beginDate,
                endDate: this._endDate,
                employees: employees,
                leaveRequestStatuses: ["APPROVED", "PENDING"]
              }
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data, errors }) => {
            if (!errors && !data.error) {
              data.filterLeaveRequests.forEach(leaveRequest => {
                if (this.isSame(leaveRequest.beginLeaveRequestDay.date, leaveRequest.endLeaveRequestDay.date)) {
                  this.events.push(this.createLeaveRequestEvent(leaveRequest, leaveRequest.beginLeaveRequestDay));
                } else {
                  for (
                    let i = 0;
                    i <= this.diff(leaveRequest.beginLeaveRequestDay.date, leaveRequest.endLeaveRequestDay.date);
                    i++
                  ) {
                    this.events.push(
                      this.createLeaveRequestEvent(
                        leaveRequest,
                        leaveRequest.leaveRequestDays[i],
                        this.add(leaveRequest.beginLeaveRequestDay.date, i)
                      )
                    );
                  }
                }
              });
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.calendarLoading = false));
      },
      fetchReminders() {
        this.calendarLoading = true;
        this.$apollo
          .query({
            query: DASHBOARD_REMINDERS,
            variables: {
              beginDate: this._beginDate,
              endDate: this._endDate,
              manager: !this.$store.state.auth.user.isAdmin ? this.$store.state.auth.user.id : null,
              branches: this.$store.state.auth.user.restrictedAdmin ? this.$store.state.auth.user.adminBranches : []
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              const { birthDates, hiringDates } = data.dashboard;
              birthDates.forEach(employee => {
                let selectedYearMonth = null;
                const birthDate = this.$moment(employee.personal.birthDate);
                switch (birthDate.format("MM")) {
                  case this.$moment(this.calendarDate).format("MM"):
                    selectedYearMonth = this.$moment(this.calendarDate).format("YYYY-MM");
                    break;
                  case this.$moment(this._beginDate).format("MM"):
                    selectedYearMonth = this.$moment(this._beginDate).format("YYYY-MM");
                    break;
                  case this.$moment(this._endDate).format("MM"):
                    selectedYearMonth = this.$moment(this._endDate).format("YYYY-MM");
                    break;
                }
                const event = {};
                const eventDate = this.$moment(`${selectedYearMonth}-${birthDate.format("DD")}`);
                event.id = employee.id;
                event.date = eventDate.format("YYYY-MM-DD");
                event.color = "indigo";
                event.icon = "mdi-cake-variant";
                event.label =
                  eventDate.diff(birthDate, "years") + ` ${this.$t("global.years_old").toLocaleLowerCase()}`;
                event.title = `${employee.firstName} ${employee.lastName}`;
                event.message = `${employee.employment.branch.name} - ${employee.employment.unit.name}`;
                event.subMessage = this.$helpers.formattedDate(employee.personal.birthDate);
                this.events.push(event);
              });
              hiringDates.forEach(employee => {
                let selectedYearMonth = null;
                const hiredDate = this.$moment(employee.employment.hiredDate);
                switch (hiredDate.format("MM")) {
                  case this.$moment(this.calendarDate).format("MM"):
                    selectedYearMonth = this.$moment(this.calendarDate).format("YYYY-MM");
                    break;
                  case this.$moment(this._beginDate).format("MM"):
                    selectedYearMonth = this.$moment(this._beginDate).format("YYYY-MM");
                    break;
                  case this.$moment(this._endDate).format("MM"):
                    selectedYearMonth = this.$moment(this._endDate).format("YYYY-MM");
                    break;
                }
                const event = {};
                const eventDate = this.$moment(`${selectedYearMonth}-${hiredDate.format("DD")}`);
                event.id = employee.id;
                event.date = eventDate.format("YYYY-MM-DD");
                event.color = "pink";
                event.icon = "mdi-balloon";
                event.label = eventDate.diff(hiredDate, "years") + `. ${this.$t("date.year").toLocaleLowerCase()}`;
                event.title = `${employee.firstName} ${employee.lastName}`;
                event.message = `${employee.employment.branch.name} - ${employee.employment.unit.name}`;
                event.subMessage = this.$helpers.formattedDate(employee.employment.hiredDate);
                this.events.push(event);
              });
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.calendarLoading = false));
      },
      fetchManagerEmployees(criteria) {
        return this.$apollo
          .query({
            query: LEAVE_MANAGER_EMPLOYEES,
            variables: { criteria },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data: { filterEmployees, error }, errors }) => {
            if (!errors && !error) {
              return filterEmployees.map(({ id }) => id);
            }
          })
          .catch(error => this.$helpers.showNotification(error.message));
      }
    },
    created() {
      this.fetchWorkplans();
      this.fetchData();
      this.initLayouts();
    }
  };
</script>

<style scoped>
  .vue-grid-layout {
    /* background: #eee; */
  }
  .vue-grid-item:not(.vue-grid-placeholder) {
    background: transparent;
    /* overflow-y: hidden; */
    touch-action: none;
    overflow-y: auto;
    /* border: 1px solid black; */
  }
  .vue-grid-item .resizing {
    opacity: 0.9;
  }
  .vue-grid-item .static {
    background: #cce;
  }
  .vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
  }
  .vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
  }
  .vue-grid-item .minMax {
    font-size: 12px;
  }
  .vue-grid-item .add {
    cursor: pointer;
  }
  .vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
      no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
  }
</style>
